@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css?family=Helvetica-Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito-Sans&display=swap');


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Avenir', 'Lato', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-container.ql-snow {
  height: unset !important;
  border: none !important;
  border-radius: 10px !important;
  max-height: 150px !important;
  overflow: auto !important;
  position: unset !important;
}

.ql-toolbar.ql-snow {
  /* border: unset !important; */
  border-top: unset !important;
  border-left: unset !important;
  border-bottom: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}

.ql-snow .ql-tooltip {
    position: absolute !important;
    top: 85% !important;
    left: 10% !important;
}